<template>
  <div id="app">
    <CookieAlert />

    <router-view />
    <el-dialog
      title="提示"
      width="26%"
      :visible.sync="isChina"
      :before-close="handleClose"
      :show-close="false"
    >
      <span class="text-center"
        >您现在访问的是国际站,{{ showmiao }}秒后为您跳转到中国站中...</span
      >
    </el-dialog>
  </div>
</template>

<script>
import i18n from "@/lang/index.js";
import CookieAlert from "./components/website/CookieAlert.vue";
export default {
  i18n,
  data() {
    return {
      isChina: false,
      miao: 3,
      showmiao: 3,
      setmiao: null,
      timer: null,
    };
  },
  components: { CookieAlert },
  created() {
    this.checkIfChina();
    this.checkDeviceAndRedirect();
  },
  methods: {
    handleClose() {
      this.isChina = false;
      clearInterval(this.setmiao);
      clearTimeout(this.timer);
    },
    async checkIfChina() {
      try {
        this.isChina = localStorage.getItem("country_name") === "China";
        if (this.isChina) {
          this.setmiao = setInterval(() => {
            this.showmiao -= 1;
          }, 1000);
          this.timer = setTimeout(() => {
            window.location.href = "https://www.macnn.top";
            clearInterval(this.setmiao);
          }, this.miao * 1000);
        }
      } catch (error) {
        this.isChina = false;
        console.error("can't get IP information:", error);
      }
    },
    checkDeviceAndRedirect() {
      // 判断是否为移动设备
      if (/Mobile|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        window.location.href =
          "https://app.macnn.xyz" +
          window.location.pathname +
          window.location.search;
      }
    },
  },
};
</script>
